// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig, parseGetParams } from './serviceManager'

export default {
  async getListAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params

    const result = await axios.get('/core/proactive-checkup/', requestConfig)
    return result.data
  },

  async getAsync(id, config) {
    const result = await axios.get(`/core/proactive-checkup/${id}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async getCheckupsOfTodayAsync(params, config) {
    const requestConfig = buildAxiosRequestConfig(config)
    requestConfig.params = params
    requestConfig.paramsSerializer = parseGetParams

    const result = await axios.get('/core/proactive-checkup/today', requestConfig)
    return result.data
  },

  async getMatrixAsync(from, to, config) {
    const result = await axios.get(`/core/proactive-checkup/matrix?from=${from}&to=${to}`, buildAxiosRequestConfig(config))
    return result.data
  },

  async createAsync(data, config) {
    const result = await axios.post('/core/proactive-checkup', data, buildAxiosRequestConfig(config))
    return result.data
  },

  async updateAsync(id, data, config) {
    const result = await axios.put(`/core/proactive-checkup/${id}`, data, buildAxiosRequestConfig(config))
    return result.data
  },

  async triggerSynchronization(config) {
    const result = await axios.post('/core/proactive-checkup/synchronize', {}, buildAxiosRequestConfig(config))
    return result.data
  },
}
