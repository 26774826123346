<template>
  <div class="pro-active-dashboard">
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group
              label="Time range"
              label-sr-only
            >
              <date-range-picker
                v-model="timeRange"
                :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last90Days']"
                :max-selectable-days="90"
              />
            </b-form-group>
            <b-form-group class="ml-1">
              <b-form-select
                v-model="selectedProductFamily"
                :options="productFamilies"
                size="sm"
                placeholder="Product family"
              />
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-overlay :show="isLoading">
      <b-card v-if="processedRows.length > 0">
        <div
          ref="matrixTable"
          class="table-responsive matrix-wrapper"
        >
          <table
            class="table table-sm table-bordered matrix"
          >
            <thead
              class="sticky-top"
              style="top: -3px;"
            >
              <tr>
                <th
                  style=""
                  :class="{ 'sticky' : stickyColumn === 'system' }"
                >
                  System
                  <feather-icon
                    v-b-tooltip.hover
                    icon="AnchorIcon"
                    size="9"
                    class="cursor-pointer"
                    :title="anchorHelpText"
                    @click="stickyColumn = 'system'"
                  />
                </th>
                <th
                  style=""
                  :class="{ 'sticky' : stickyColumn === 'product' }"
                >
                  Product
                  <feather-icon
                    v-b-tooltip.hover
                    icon="AnchorIcon"
                    size="9"
                    class="cursor-pointer"
                    :title="anchorHelpText"
                    @click="stickyColumn = 'product'"
                  />
                </th>
                <th
                  style=""
                  :class="{ 'sticky' : stickyColumn === 'hostname' }"
                >
                  Hostname
                  <feather-icon
                    v-b-tooltip.hover
                    icon="AnchorIcon"
                    size="9"
                    class="cursor-pointer"
                    :title="anchorHelpText"
                    @click="stickyColumn = 'hostname'"
                  />
                </th>
                <th
                  v-for="index in timeRangeDiffDays"
                  :key="index"
                >
                  <div
                    class="vertical"
                  >
                    {{ getTableHeaderShortDate(index) }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in processedRows"
                :key="row.asset.id"
              >
                <td
                  style="white-space: nowrap"
                  :class="{ 'sticky' : stickyColumn === 'system' }"
                >
                  <b-link :to="getSystemLink(row.asset)">
                    {{ row.asset.systemNumber }}
                  </b-link>
                </td>
                <td
                  style="white-space: nowrap"
                  :class="{ 'sticky' : stickyColumn === 'product' }"
                >
                  {{ row.asset.productFamily }}
                </td>
                <td
                  style="white-space: nowrap"
                  :class="{ 'sticky' : stickyColumn === 'hostname' }"
                >
                  {{ row.asset.hostname }}
                </td>
                <td
                  v-for="index in timeRangeDiffDays"
                  :id="getCheckupCellId(row.asset.id, index)"
                  :key="index"
                  class="matrix-status-cell"
                  :class="{
                    'bg-success': row.columns[index] && row.columns[index].status === 0,
                    'bg-warning': row.columns[index] && row.columns[index].status === 1,
                    'bg-danger': row.columns[index] && row.columns[index].status === 2,
                    'table-secondary': isWeekend($moment(timeRange.startDate).add(index, 'days'))
                  }"
                >

                  <checkup-popover
                    v-if="row.columns[index]"
                    ref="checkupPopover"
                    :target="getCheckupCellId(row.asset.id, index)"
                    :checkup-id="row.columns[index].id"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card>

      <b-card v-if="showMarketing">
        <p>
          Kunden mit dem mentIQ ProActive Service sehen hier eine Checkup-Matrix über all ihre ProAktiven Systeme.<br>
          Dies beinhaltet den Status (Rot, Gelb, Grün), der Prüfzeit und dem Prüfer.
        </p>
        <p>Sie möchten die Vorteile unseres ProActive Services kennenlernen? Unsere Mitarbeiter helfen Ihnen gerne weiter: <a href="tel:089954157220">089 - 95 415 72 - 20</a> / <a href="mailto:info@mentiq.com">info@mentiq.com</a></p>

        <b-img
          fluid
          src="@/assets/images/marketing/proactive-checkup-matrix.jpg"
        />
      </b-card>
    </b-overlay>

  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BForm, BFormGroup, BOverlay, BFormSelect, BLink, VBTooltip, BImg,
} from 'bootstrap-vue'

import CheckupPopover from './checkupPopover.vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import DailyCheckupService from '@/service/dailycheckup.service'
import moment from '@/libs/moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BOverlay,
    BFormSelect,
    BLink,
    BImg,
    CheckupPopover,
    DateRangePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      stickyColumn: 'hostname',
      matrix: {},
      timeRange: {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: new Date(),
      },
      selectedProductFamily: null,
    }
  },
  computed: {
    anchorHelpText() {
      return 'Click here to make this column sticky while scrolling'
    },
    timeRangeDiffDays() {
      return this.$moment(this.timeRange.endDate).diff(this.$moment(this.timeRange.startDate), 'days')
    },
    processedRows() {
      let result = this.matrix.rows

      if (this.selectedProductFamily) {
        result = this.matrix.rows
          .filter(x => x.asset !== null && x.asset.productFamily === this.selectedProductFamily)
      }

      return result
    },
    productFamilies() {
      const notSelected = { value: null, text: 'Product family' }
      if (this.matrix.rows) {
        // get product families distinct
        const productFamilies = this.matrix.rows
          .filter(x => x.asset !== null)
          .map(x => x.asset.productFamily)
          .filter((value, index, self) => self.indexOf(value) === index)

        // create options object for select
        const productFamilyOptions = productFamilies.map(x => ({ value: x, text: x }))

        // merge notSelected option and all options of productFamily
        return [notSelected, ...productFamilyOptions]
      }

      return [notSelected]
    },
    showMarketing() {
      if (this.isLoading === false && this.matrix.rows && this.matrix.rows.length === 0) {
        return true
      }

      return false
    },
  },
  watch: {
    timeRange() {
      this.loadData()
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      const from = moment.utc(this.timeRange.startDate).startOf('day').toISOString()
      const to = moment.utc(this.timeRange.endDate).endOf('day').toISOString()

      DailyCheckupService.getMatrixAsync(from, to, { disableTenantFilter: true })
        .then(result => {
          this.matrix = result

          // scroll matrix to the right after data is loaded and rendered in DOM
          this.$nextTick(() => {
            const matrixTableEl = this.$refs.matrixTable
            matrixTableEl.scrollLeft = matrixTableEl.scrollWidth
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getTableHeaderShortDate(dayIndex) {
      return moment(this.timeRange.startDate).add(dayIndex, 'days').format('DD.MM')
    },
    isWeekend(date) {
      const weekday = this.$moment(date).isoWeekday()
      return weekday === 6 || weekday === 7 // saturday or sunday
    },
    getCheckupCellId(assetId, dayIndex) {
      return `checkup_${assetId}_${dayIndex}`
    },
    getSystemLink(asset) {
      // if asset has asupSystemId, we link it to asup page, otherwise to asset detail page
      if (asset.asupSystemId) {
        return { name: 'asset-to-asup-redirect', params: { id: asset.id }, query: { ar: true } }
      }

      return { name: 'asset-detail-page', params: { id: asset.id } }
    },
  },
}

</script>

<style lang="scss">
  @import '@/assets/scss/proactive-checkup-dashboard.scss';
</style>
