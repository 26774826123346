<template>
  <div>
    <b-popover
      triggers="hover"
      :target="target"
      :variant="checkupStatusVariant"
      @show="onShow"
    >
      <template #title>
        ProActive Checkup
      </template>

      <b-overlay
        :show="isLoading"
      >
        <dl>
          <dt>System number</dt>
          <dd>{{ asset.systemNumber }}</dd>

          <dt>Product family</dt>
          <dd>{{ asset.productFamily }}</dd>

          <dt>Hostname</dt>
          <dd>{{ asset.hostname }}</dd>

          <dt>Checked on</dt>
          <dd>{{ checkup.checkedOn | formatDateTime }}</dd>

          <dt>Checked by</dt>
          <dd>{{ checkedByName }}</dd>

          <dt>Status</dt>
          <dd>
            {{ checkupStatusDisplayName }}
          </dd>

          <dt>Note</dt>
          <dd>{{ checkup.note }}</dd>
        </dl>
      </b-overlay>
    </b-popover>
  </div>
</template>

<script>

import {
  BOverlay, BPopover,
} from 'bootstrap-vue'

import DailyCheckupService from '@/service/dailycheckup.service'

export default {
  components: {
    BOverlay, BPopover,
  },
  props: {
    target: {
      type: String,
      required: true,
    },
    checkupId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      checkup: {},
    }
  },
  computed: {
    asset() {
      return this.checkup.asset || {}
    },
    checkedBy() {
      return this.checkup.checkedBy || {}
    },
    checkedByName() {
      if (this.checkedBy) {
        if (this.checkedBy.firstname && this.checkedBy.lastname) {
          return `${this.checkedBy.firstname} ${this.checkedBy.lastname}`
        }

        return this.checkedBy.email
      }

      return '?'
    },
    checkupStatusDisplayName() {
      if (this.checkup.status === 0) return 'OK'
      if (this.checkup.status === 1) return 'Error resolved'
      if (this.checkup.status === 2) return 'Error not resolved'
      return 'N/A'
    },
    checkupStatusVariant() {
      if (this.checkup.status === 0) return 'success'
      if (this.checkup.status === 1) return 'warning'
      if (this.checkup.status === 2) return 'danger'
      return ''
    },
  },
  methods: {
    onShow() {
      this.isLoading = true

      DailyCheckupService.getAsync(this.checkupId)
        .then(result => {
          this.checkup = result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}

</script>
